<template>
  <menubar />
  <main-container>
    <template v-slot:header>
      <return-container />
    </template>

    <div id="a-add-questions-container">
      <div
        id="a-question-container"
        v-for="(question, index) in questions"
        :key="index"
      >
        <div id="a-title">
          <span>Pregunta</span>
          <span id="a-number" v-if="index < 9">0{{ index + 1 }}</span>
          <span id="a-number" v-else>{{ index + 1 }}</span>
        </div>
        <div id="a-question-content">
          <div class="a-question-type">
            <Dropdown
              class="a-question-type"
              v-model="question.questionType"
              :options="questionTypes"
              optionLabel="name"
              placeholder="Tipo de pregunta"
              :showClear="false"
              :disabled="Object.keys(question).length > 1"
            />
            <button
              v-if="Object.keys(question).length == 1"
              :disabled="question.questionType == null"
              @click="setNewQuestionStructure"
              class="a-rounded-icon-btn"
            >
              +
            </button>
          </div>
          <div class="p-field" v-if="Object.keys(question).length > 1">
            <Textarea
              :autoResize="false"
              rows="5"
              cols="25"
              placeholder="Escribe la pregunta"
              v-model="question.name"
            />
          </div>

          <div
            v-if="
              Object.keys(question).length > 1 &&
              question.questionType.name != 'Pregunta abierta'
            "
          >
            <div
              v-for="(answer, indexA) in question.options"
              :key="`question${index}--answer${indexA}`"
              class="a-answer-option"
            >
              <span id="a-number" v-if="index < 9">0{{ indexA + 1 }}.</span>
              <span id="a-number" v-else>{{ indexA + 1 }}.</span>
              <InputText
                type="text"
                :id="`question${index}--answer${indexA}`"
                :name="`question${index}--answer${indexA}`"
                placeholder="Añadir opción"
                v-model="answer.name"
              />
              <div>
                <span id="a-inside-text">Valor</span>
                <InputNumber
                  class="a-weight-option"
                  v-model="answer.weighted"
                  suffix="%"
                  :min="0"
                  :max="100"
                />
              </div>
            </div>
            <div @click="setNewOption(index)" class="a-answer-option">
              <span id="a-number">##. </span>
              <span class="p-inputtext">Añadir opción</span>
            </div>
          </div>

          <div
            class="a-extra"
            v-if="
              Object.keys(question).length > 1 && question.questionType != null
            "
          >
            <div></div>
            <div class="p-field">
              <label for="a-test-name">Valor total</label>
              <InputText
                type="text"
                placeholder="0"
                v-model="question.weighted"
              />
            </div>
            <div
              class="p-field"
              v-if="
                question.questionType.name !=
                'Selección múltiple. Única respuesta'
              "
            >
              <label for="a-test-name">Valor aprobado</label>
              <InputText
                type="text"
                placeholder="0"
                v-model="question.approved"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="a-form-buttons">
        <primary-button
          :title="'Crear'"
          :isValid="isValid"
          :isLoading="isLoading"
          @onClick="newQuestion"
        />
        <Button label="Regresar" class="p-button-danger" />
      </div>
    </div>
  </main-container>
</template>

<script>
import Menubar from "../../../components/navigation/Top.vue";
import MainContainer from "../../../components/secure/MainContainer.vue";
import ReturnContainer from "@/components/navigation/ReturnContainer.vue";
import PrimaryButton from "../../../components/widgets/PrimaryButton.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Menubar,
    MainContainer,
    ReturnContainer,
    PrimaryButton,
  },
  data() {
    return {
      isLoading: false,
      videoUploaded: false,
      questions: [
        {
          questionType: null,
        },
      ],
      baseQuestionStructure: {
        name: "",
        weighted: null,
        approved: null,
        test: 1,
        active: true,
      },
      optionStructure: {
        name: "",
        weighted: null,
        active: true,
        question: null,
      },
    };
  },
  computed: {
    ...mapState("miscellaneous", ["questionTypes"]),
    ...mapState("test", ["uploadedTest", "file"]),
    isValid() {
      return true;
    },
  },
  methods: {
    ...mapMutations("test", ["resetTest"]),
    setNewQuestionStructure() {
      if (
        this.questions[this.questions.length - 1].questionType.name ==
        "Pregunta abierta"
      ) {
        this.questions[this.questions.length - 1] = {
          ...this.questions[this.questions.length - 1],
          ...this.baseQuestionStructure,
        };
      } else {
        var questionStructure = {
          ...this.baseQuestionStructure,
          options: [{ ...this.optionStructure }],
        };
        this.questions[this.questions.length - 1] = {
          ...this.questions[this.questions.length - 1],
          ...questionStructure,
        };
      }
      if (this.questions.length < this.uploadedTest.questions)
        this.questions.push({
          questionType: null,
        });
    },
    newQuestion() {
      this.$store.dispatch("auth/updateToken");
      let finished = 0;

      this.questions.forEach((question) => {
        if (question.questionType.name == "Pregunta abierta") {
          question.options = [];
        }
        if (
          question.questionType.name == "Selección múltiple. Única respuesta"
        ) {
          question.approved = 100;
        }
        this.$store
          .dispatch("test/SAVE_QUESTION", question)
          .then(() => {
            finished++;
            if (finished == this.questions.length) {
              this.resetTest();
              this.$router.push({ name: "Tests" });
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          });
      });
    },
    uploadVideo() {
      let testToUpdate = this.uploadedTest;
      testToUpdate.video = this.file;
      var form_data = new FormData();
      for (var key in testToUpdate) {
        form_data.append(key, testToUpdate[key]);
      }
      this.$store
        .dispatch("test/UPDATE_TEST", form_data)
        .then(() => {
          this.videoUploaded = true;
        })
        .catch((error) => {
          if (error == "token") {
            this.uploadVideo();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    getQuestionTypes() {
      this.$store
        .dispatch("miscellaneous/GET_QUESTIONS_TYPES")
        .catch((error) => {
          if (error == "token") {
            this.getQuestionTypes();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    deleteQuestion() {},
    setNewOption(questionIndex) {
      this.questions[questionIndex].options.push({
        name: "",
        weighted: null,
        active: true,
        question: null,
      });
    },
  },
  mounted() {
    if (this.uploadedTest != null) {
      this.uploadVideo();
      if (this.$store.state.miscellaneous.questionTypes == null) {
        this.getQuestionTypes();
      }
    } else {
      this.$router.push({ name: "Tests" });
    }
  },
};
</script>

<style>
html,
body,
#app {
  background: white;
}

#a-question-container .p-inputtext {
  background: var(--color-4);
  box-shadow: none;
}

#a-question-container .p-inputtext::placeholder,
textarea::placeholder {
  text-align: left;
}

.a-main-content {
  padding: 0;
}
#a-question-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}
#a-question-container #a-title {
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
#a-question-container #a-title span {
  writing-mode: vertical-rl;
  transform: scale(-1);
  margin: 2px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.36px;
  text-align: left;
  color: #2b5ac4;
}
#a-question-container #a-title #a-number {
  opacity: 0.3;
  font-family: Quicksand;
  font-size: 50px;
  font-weight: bold;
  line-height: 0.77;
  letter-spacing: -3.25px;
  text-align: left;
  color: #00d784;
}
#a-question-container #a-question-content {
  width: 90%;
  margin: 20px 26px 20px 16px;
  padding: 45.3px 55px 32px 56px;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
}
.a-answer-option {
  display: flex;
  align-items: center;
}
.a-extra {
  position: absolute;
  display: flex;
  max-width: 50%;
  bottom: -30px;
  right: 55px;
}
.a-answer-option .p-inputtext,
.a-question-type .p-inputtext {
  background: white !important;
}
.a-weight-option .p-inputtext {
  width: 100px;
  height: 10px;
  border: 2px solid var(--color-4) !important;
}
#a-inside-text {
  font-size: 13px;
  margin-right: 2px;
}
.a-question-type {
  display: flex;
  flex-flow: row nowrap;
}
.a-question-type > * {
  margin: 10px;
}
</style>
